
import { Box, styled } from '@mui/material'
import BgImg from "../../../images/job_hero.png";

const HeroWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(
    to bottom,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.main} 20%,
    rgba(250, 250, 250, 0) 20%
  ), url(${BgImg})`,
  [theme.breakpoints.up("md")]: {
    backgroundImage: `linear-gradient(to left, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 20%, rgba(250, 250, 250, 0) 20%), url(${BgImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: "calc(100vh - 224px)",
    border: `32px solid ${theme.palette.primary.main}`,
    borderTop: "none",
  },
}));

export default HeroWrapper
