import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";

import SvgText from "../../JoinUs/Benefits/SvgText";

import likeIcon from "@iconify/icons-ei/like";
import BgImg from "../../../images/benefits.png";
import BgImagen from "../../../images/benefits2.png";
import ImgSearch from "../../../images/job_Offer.png";
import checkCircleFilled from "@iconify/icons-ant-design/check-circle-filled";

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const StyledBox = styled(Box)`
  width: 100%;
`;

const Image = styled("img")(({ theme }) => ({
  overflow: "hidden",
  height: "350px",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const CareerOpportunities = () => (
  <Box>
    <StyledBox bgcolor="white" py={2}>
      <Box m="0 auto" maxWidth="1600px">
        <Box p={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <SectionTitle
                sx={{
                  color: "rgb(45,34,74)",
                  borderBottom: "1px solid rgb(45,34,74)",
                }}
                color="black"
              >
                Unit Linked
              </SectionTitle>
            </Grid>
            <Grid item container justifyContent="center" xs={12} md={6}>
              <Image src={BgImg} />
            </Grid>
            <Grid item container alignItems="center" xs={12} md={6}>
              <SvgText
                description="Puedes acceder como inversor a los mercados financieros nacionales e internacionales ya que desde Fibexty ponemos a tu disposición un amplio portafolio con las diferentes modalidades de Seguros Unit Linked que se pueden encontrar en las principales Compañías Aseguradoras del Mercado."
                color="black"
                fill="black"
                side
                icon={likeIcon}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledBox>
    <StyledBox sx={{ bgcolor: "rgb(45,34,74)" }} py={2}>
      <Box m="0 auto" maxWidth="1600px">
        <Box p={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <SectionTitle
                color="white"
                sx={{ borderBottom: "1px solid white" }}
              >
                PIAS
              </SectionTitle>
            </Grid>

            <Grid item container alignItems="center" xs={12} md={6}>
              <SvgText
                description="
              Se trata de un Plan Individual de Ahorro Sistemático que te permite invertir en carteras de inversión según sea tu perfil de riesgo y obtener de este modo una atractiva rentabilidad, acumulando un capital y dando valor a un ahorro a largo plazo."
                color="white"
                fill="#fff"
                icon={checkCircleFilled}
              />
            </Grid>
            <Grid item container justifyContent="center" xs={12} md={6}>
              <Image src={ImgSearch} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledBox>
    <StyledBox bgcolor="white.100" py={2}>
      <Box bgcolor="white.100" m="0 auto" maxWidth="1600px">
        <Box p={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <SectionTitle
                sx={{
                  color: "rgb(45,34,74)",
                  borderBottom: "1px solid rgb(45,34,74)",
                }}
              >
                SIALP
              </SectionTitle>
            </Grid>
            <Grid item container justifyContent="center" xs={12} md={6}>
              <Image src={BgImagen} />
            </Grid>
            <Grid item container alignItems="center" xs={12} md={6}>
              <SvgText
                description="Se trata de un Seguro Individual de Ahorro a Largo Plazo en el que tendrás una exención fiscal total en los rendimientos que obtengas, siempre que no efectúes disposición alguna del capital resultante de tu póliza antes de finalizar el plazo mínimo de 5 años desde su apertura."
                color="black"
                fill="black"
                side
                icon={likeIcon}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledBox>
  </Box>
);

export default CareerOpportunities;
