import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import HeroWrapper from "./HeroWrapper";

interface Props {
  titleProgram: string;
  subtitle: string;
  suggestion: string;
}

const Hero = ({ titleProgram, subtitle, suggestion }: Props) => (
  <HeroWrapper id="hero">
    <Box pt={3}>
      <Grid
        container
        data-aos="fade-left"
        data-aos-duration="1000"
        flexDirection="column"
        justifyContent={["flex-end", "center"]}
        alignItems="flex-end"
        minHeight={["80vh", "inherit"]}
      >
        <Box
          px={5}
          py={6}
          bgcolor="rgb(255, 82, 0)"
          maxWidth="610px"
          color="white"
        >
          <Typography mb={3} variant="body1">
            {subtitle}
          </Typography>
          <Typography variant="h1">{titleProgram}</Typography>
          <Typography mt={3} variant="body1">
            {suggestion}
          </Typography>
        </Box>
      </Grid>
    </Box>
  </HeroWrapper>
);

export default Hero;
