/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "@iconify/react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

interface Props {
  icon?: any;
  description: string;
  color: string;
  fill: string;
  side?: boolean;
  email?: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  textAlign: "start",
  [theme.breakpoints.up("lg")]: {
    justifyContent: "center",
  },
}));

const StyledRightBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
  textAlign: "center",
  [theme.breakpoints.up("lg")]: {
    justifyContent: "center",
    textAlign: "center !important",
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: "50px",
  height: "50px",
  color: "white",
  // [theme.breakpoints.up("lg")]: {
  //   width: "140px",
  //   height: "140px",
  // },
}));

const EmailLink = styled("a")`
  transition: all 0.2s ease-in;
  text-decoration: none;
  margin-left: 10px;
  color: rgb(255, 82, 0);
  &:hover {
    opacity: 0.8;
  }
`;

const SvgText = ({ icon, description, color, fill, side, email }: Props) => (
  <>
    {side ? (
      <StyledBox
        py={3}
        px={2}
        display={["block", "flex"]}
        data-aos="flip-right"
      >
        <Box px={3}>
          <StyledIcon icon={icon} color={fill} />
        </Box>
        <Typography
          position="relative"
          zIndex="2"
          variant="h3"
          mb={0}
          color={color}
        >
          {description}
        </Typography>
      </StyledBox>
    ) : (
      <StyledRightBox
        py={3}
        px={2}
        display={["block", "flex"]}
        data-aos="flip-left"
      >
        <Typography
          position="relative"
          zIndex="2"
          variant="h3"
          mb={0}
          color={color}
          style={{ width: "100%" }}
        >
          {description}
          <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
        </Typography>
        <Box px={3}>
          <StyledIcon icon={icon} color={fill} />
        </Box>
      </StyledRightBox>
    )}
  </>
);

export default SvgText;
