import React from 'react'
import type { HeadFC } from "gatsby";

import Layout from '../components/Layout'
import { SEO } from '../components/SEO';
import Hero from '../screen/Fibexty/Hero';
import CareerOpportunities from '../screen/Fibexty/Benefits';

const Fibexty = () => {
  return (
    <Layout>
      <Hero
        subtitle="Inversión y Ahorro"
        titleProgram="En Fibexty te ofrecemos soluciones de Inversión y Ahorro a medio y largo plazo, contamos con diversas alternativas de productos en función de tus necesidades."
        suggestion="Ver abajo los tipos de productos que ofrecemos "
      />
      <CareerOpportunities></CareerOpportunities>
    </Layout>
  )
}

export default Fibexty

export const Head: HeadFC = () => <SEO title="Unete a nosotros - Fibexty"></SEO>;